import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Collapse, Table, Modal, Form } from 'react-bootstrap';
import { useAuth } from '../utils/auth';
import Swal from 'sweetalert2'; // Import SweetAlert2

const MyDataP = () => {
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState(null);
  const [isOpen3, setisOpen3] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false); // Add loading state

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      fetchSiteDataPlans(token);
    }
  }, [navigate]);

  const fetchSiteDataPlans = async (token) => {
    const domain = localStorage.getItem('domain');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/siteDataPlans`, {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`,
          'domain': domain 
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch site data plans');
      }
      const responseData = await response.json();
      if (responseData.status === 'success') {
        setSiteData(responseData.siteData);
      }
    } catch (error) {
      console.error('ERROR FETCHING PLAN:', error);
    }
  };

  const handleEditClick = (plan) => {
    setSelectedPlan(plan);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setSelectedPlan(null);
    setShowModal(false);
    setIsLoading(false); // Reset loading state after closing modal
  };

  const getToken = () => {
    return localStorage.getItem('userToken');
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true
    const formData = new FormData(e.target);
    const updatedPlan = {
      endUserAmount: formData.get('endUserAmount'),
      resellerAmount: formData.get('resellerAmount'),
      AgentAmount: formData.get('AgentAmount'),
      webOwnerAmount: formData.get('webOwnerAmount'),
      OneGB: formData.get('OneGB'),
      status: formData.get('status'),
      universalPlanID: selectedPlan.id,
      updateNetwork: formData.get('updateNetwork'),
      updatePlanType: formData.get('updatePlanType'),
    };
    const domain = localStorage.getItem('domain');
    const token = getToken();

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/updatePlan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ domain, ...updatedPlan }),
      });
      if (!response.ok) {
        throw new Error('Failed to update plan');
      }
      // Successfully updated plan, now show SweetAlert
      Swal.fire({
        title: 'Plan Updated Successfully!',
        text: 'Would you like to refresh the page to see the updated data?',
        icon: 'success',
        showCancelButton: true,
        confirmButtonText: 'Refresh',
        cancelButtonText: 'Just Close',
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        } else {
          handleCloseModal(); 
        }
      });

      fetchSiteDataPlans(localStorage.getItem('userToken'));
      handleCloseModal();
    } catch (error) {
      console.error('Error updating plan:', error);
      // Handle potential errors and show an error alert
      Swal.fire({
        title: 'Error!',
        text: 'Something went wrong. Please try again later.',
        icon: 'error'
      });
      setIsLoading(false); // Reset loading state if error occurred
    }
  };

  return (
    <Card className="mb-1">
      <Card.Header style={{ backgroundColor: 'green', textAlign: 'center', fontWeight: 'bold' }}>
        <Button variant="link" onClick={() => setisOpen3(!isOpen3)} aria-controls="collapse-card-3" aria-expanded={isOpen3}  style={{ color: 'white' }}>
          My Data Plan 
        </Button>
      </Card.Header>
      <Collapse in={isOpen3}>
        <Card.Body>
          <b>Updating the pricing or status of the 1GB option for any plan type will automatically update the pricing and status for the entire plan type. While you can update the pricing for individual data sizes separately, adjusting the 1GB option across each size and plan type will save you time by applying the changes automatically.</b>
          {siteData ? (
            <Table striped bordered hover responsive>
              <thead>
                <tr>
                  <th>Network</th>
                  <th>Pricing</th>
                </tr>
              </thead>
              <tbody>
                {siteData.map((plan) => (
                  <tr key={plan.id}>
                    <td>{plan.network} {plan.planType} ({plan.dataSize} {plan.sizeType})<br />
                      <small>{plan.validity}</small><br />
                      <b style={{ color: plan.active ? 'green' : 'red' }}>{plan.active ? 'ACTIVE' : 'DISABLED'}</b><br />
                      <Button variant="primary" size="sm" onClick={() => handleEditClick(plan)}>Edit</Button>
                    </td>
                    <td><small>Endusers: {plan.endUserAmount}<br />
                      Agents: {plan.AgentAmount}<br />
                      Resellers: {plan.resellerAmount}<br />
                      Affiliate: {plan.webOwnerAmount}</small></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            <p>No data plans available</p>
          )}
        </Card.Body>
      </Collapse>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Edit {selectedPlan?.network} {selectedPlan?.planType} ({selectedPlan?.dataSize} {selectedPlan?.sizeType})</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleFormSubmit}>
            <Form.Control type="hidden" name="universalPlanID" value={selectedPlan?.id} />
            <Form.Control type="hidden" name="domain" value={window.location.hostname} />
            <Form.Control type="hidden" name="updateNetwork" defaultValue={selectedPlan?.network} />
            <Form.Control type="hidden" name="updatePlanType" defaultValue={selectedPlan?.planType} />

            {selectedPlan && (
              <>
                <h3>{selectedPlan.dataSize === '1' && selectedPlan.sizeType === 'GB' ? 'You are making changes to entire plan in this category' : 'You are making Change to this plan only'}</h3>
                <br />
                <Form.Group controlId="formEndUserAmount">
                  <Form.Label>End User Amount</Form.Label>
                  <Form.Control type="text" name="endUserAmount" defaultValue={selectedPlan.endUserAmount || ''} />
                </Form.Group>

                <Form.Group controlId="formAgentAmount">
                  <Form.Label>Agent Amount</Form.Label>
                  <Form.Control type="text" name="AgentAmount" defaultValue={selectedPlan.AgentAmount || ''} />
                </Form.Group>

                <Form.Group controlId="formResellerAmount">
                  <Form.Label>Reseller Amount</Form.Label>
                  <Form.Control type="text" name="resellerAmount" defaultValue={selectedPlan.resellerAmount || ''} />
                </Form.Group>

                <Form.Group controlId="formWebAffiliateAmount">
                  <Form.Label>Web Affiliate Amount</Form.Label>
                  <Form.Control type="text" name="webOwnerAmount" defaultValue={selectedPlan.webOwnerAmount || ''} />
                  <Form.Control type="hidden" name="OneGB" defaultValue={selectedPlan.dataSize === '1' && selectedPlan.sizeType === 'GB' ? '0' : selectedPlan.id} />
                </Form.Group>

                <Form.Group controlId="formStatus">
                  <Form.Label>Status</Form.Label>
                  <Form.Check
                    type="radio"
                    label="Activate"
                    name="status"
                    value="1"
                    defaultChecked={selectedPlan && selectedPlan.active === 1}
                  />
                  <Form.Check
                    type="radio"
                    label="Deactivate"
                    name="status"
                    value="0"
                    defaultChecked={selectedPlan && selectedPlan.active === 0}
                  />
                </Form.Group>
                <br />
                <Button variant="link" className="text-danger">Delete Plan</Button>
                <Button variant="primary" type="submit" disabled={isLoading}>
                  {isLoading ? 'Updating...' : 'Update Plan'} 
                </Button>
              </>
            )} 
          </Form>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default MyDataP;