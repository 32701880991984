import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Card, Form, Button, Collapse, Table } from 'react-bootstrap';
import { useAuth } from '../utils/auth';

const SiteApisMng = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [apis, setApis] = useState([]);
  const [clubkonnectUserId, setClubkonnectUserId] = useState(''); // Initialize as a string
  const [isOpen3, setIsOpen3] = useState(false);
  const [apiKeys, setApiKeys] = useState({});
  const [isAlpha, setIsAlpha] = useState(null); // State for isAlpha
  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
      fetchApiData(token);
      fetchApiKeys(token);
    }
  }, [navigate]);

  const validateToken = async (token) => {
    const domain = localStorage.getItem('domain');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/adminInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Domain': domain
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();
      if (responseData.status === 'failed') {
        localStorage.clear();
        navigate('/');
      }
    } catch (error) {
      localStorage.clear();
      navigate('/');
    }
  };








  





  const fetchApiData = async (token) => {
    try {
      const domain = localStorage.getItem('domain');
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/ApiService`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Domain': domain

        },
        body: JSON.stringify({ domain }),
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch API data');
      }
  
      const responseData = await response.json();
      setData(responseData.services); // Update state with service data
      setApis(responseData.apis); // Update state with API data
      setClubkonnectUserId(responseData.clubkonnectUserId); // Update state with clubkonnectUserId
    } catch (error) {

    }
  };
  
  const fetchApiKeys = async (token) => {
    const domain = localStorage.getItem('domain');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/adminSiteInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Domain': domain
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to fetch API keys');
      }
  
      const responseData = await response.json();
      if (responseData.success) {
        const keys = {};
        responseData.rows.forEach(row => {
          keys[row.apiName] = row.apiKey;
        });
        setApiKeys(keys);
        setIsAlpha(responseData.rows[0].isAlpha);
      } else {
      }
    } catch (error) {
    }
  };

  const handleApiChange = async (service, newApiName) => {
    const token = localStorage.getItem('userToken');
    const domain = localStorage.getItem('domain');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/changeApi`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Domain': domain,
        },
        body: JSON.stringify({ service, newApiName, domain }),
      });

      const result = await response.json();
      alert(result.message);
      await fetchApiData(token); // Fetch new data to update table
    } catch (error) {
    }
  };

  const handleApiKeyChange = (apiName, apiKey) => {
    setApiKeys({ ...apiKeys, [apiName]: apiKey });
  };

  const handleSaveApiKey = async (apiName) => {
    const token = localStorage.getItem('userToken');
    const domain = localStorage.getItem('domain');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/admin/fixApi`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Domain': domain

        },
        body: JSON.stringify({ apiName, apiKey: apiKeys[apiName], domain }),
      });

      const result = await response.json();
      alert(result.message);
      await fetchApiKeys(token); // Fetch new keys to update table
    } catch (error) {
    }
  };

  const groupByService = (services) => {
    return services.reduce((acc, service) => {
      if (!acc[service.service]) {
        acc[service.service] = {
          service: service.service,
          defaultApi: service.defaultApi
        };
      }
      return acc;
    }, {});
  };

  const groupedServices = Object.values(groupByService(data));

  if (isAlpha === 1) {
    return (
      <Container>
        <Card className="mb-1">
          <Card.Header style={{ backgroundColor: 'blue', textAlign: 'center', fontWeight: 'bold' }}>
            <Button
              variant="link"
              onClick={() => setIsOpen3(!isOpen3)}
              aria-controls="collapse-card-2"
              aria-expanded={isOpen3}
              style={{ color: 'white' }}
            >
              Service API Management
            </Button>
          </Card.Header>
          <Collapse in={isOpen3}>
            <Card.Body>
            <center>You must have added the api key of any Vending site you want to use before changing your current API to it, else your service will not be functional!</center>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Service</th>
                    <th>Current API</th>
                  </tr>
                </thead>
                <tbody>
                {groupedServices.map((service, index) => (
  <tr key={index}>
    <td>{service.service}</td>
    <td>
      <Form.Control
        as="select"
        value={service.defaultApi}
        onChange={(e) => handleApiChange(service.service, e.target.value)}
      >
        <option value="">Select</option>
        {apis
          .filter(api => !['ibrolinksPin', 'ibrolinksSecrete', 'clubkonnectUserId'].includes(api.apiName))
          .map((api, apiIndex) => (
            <option key={apiIndex} value={api.apiName}>
              {api.apiName}
            </option>
          ))}
      </Form.Control>
    </td>
  </tr>
))}

                  {clubkonnectUserId && (
                    <tr>
                      <td>Clubkonnect User ID</td>
                      <td>
                        <Form.Control
                          type="text"
                          value={clubkonnectUserId}
                          readOnly
                        />
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <br />
              <b>API ACCOUNT MANAGEMENT</b>
              <hr />
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>API Name</th>
                    <th>API Key</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {apis.map((api, index) => (
                    <tr key={index}>
                      <td>{api.apiName}</td>
                      <td>
                        <Form.Control
                          type="text"
                          value={apiKeys[api.apiName] || ''}
                          onChange={(e) => handleApiKeyChange(api.apiName, e.target.value)}
                        />
                      </td>
                      <td>
                        <Button
                          onClick={() => handleSaveApiKey(api.apiName)}
                        >
                          Save
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Card.Body>
          </Collapse>
        </Card>
      </Container>
    );
  }

  return null;
};

export default SiteApisMng;
