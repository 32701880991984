import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import Loading from '../components/Loading';
import Swal from 'sweetalert2';

const DataPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [oldTransactionPin, setOldTransactionPin] = useState('');
  const [newTransactionPin, setNewTransactionPin] = useState('');
  const [userType, setUserType] = useState('');
  const [formError, setFormError] = useState('');
  const [Token, setToken] = useState('');
  const [myToken, setmyToken] = useState('');
  const [userTypePricing, setUserTypePricing] = useState({
    agentUpgradeAmount: 0,
    resellerUpgradeAmount: 0,
    webOwnerUpgradeAmount: 0,
  });
  const [selectedUserCategory, setSelectedUserCategory] = useState('');

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    const domain = window.location.hostname;

    if (!token) {
      navigate('/');
    } else {
      fetchUserData(token, domain);
      fetchUserTypePricing(domain);
    }
  }, [navigate]);

  const fetchUserData = async (token, domain) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', // This should be the only Content-Type header
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }), // Send token and domain in the body
      });

      if (!response.ok) throw new Error('Failed to fetch user data');

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
        return;
      }

      
      setmyToken(token);

      setUser(responseData.user || {});
      setName(responseData.user.fullName || '');
      setPhoneNumber(responseData.user.phone || '');
      setOldTransactionPin(responseData.user.transactionPin || '');
      setToken(responseData.user.Token || '');
      setUserType(responseData.user.userType || '');
      setSelectedUserCategory(responseData.user.userType || '');
    } catch (error) {
      console.error('Error fetching user data:', error);
      localStorage.removeItem('userToken');
      navigate('/');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserTypePricing = async (domain) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/site/info`, {
        method: 'POST',
        headers: { 'domain': domain },
      });

      if (!response.ok) throw new Error('Failed to fetch user type pricing');

      const data = await response.json();

      if (data.success) {
        setUserTypePricing({
          agentUpgradeAmount: data.agentUpgradeAmount,
          resellerUpgradeAmount: data.resellerUpgradeAmount,
          webOwnerUpgradeAmount: data.webOwnerUpgradeAmount,
        });
      } else {
        console.error('Error fetching user type pricing:', data.error);
      }
    } catch (error) {
      console.error('Error fetching user type pricing:', error);
    }
  };

  const handleProfileUpdate = async () => {
    if (password !== confirmPassword) {
      setFormError('Passwords do not match');
      return;
    }

    try {
      const domain = window.location.hostname;
      const token = localStorage.getItem('userToken');

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/AccountEdit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Send the token in the Authorization header
        },
        body: JSON.stringify({
          name,
          password,
          phoneNumber,
          oldTransactionPin,
          newTransactionPin,
          domain,
        }),
      });

      if (response.ok) {
        Swal.fire({ icon: 'success', title: 'Success!', text: 'Profile updated successfully' });
      } else {
        Swal.fire({ icon: 'error', title: 'Failed!', text: 'Failed to update profile' });
      }
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error!', text: 'Error updating profile' });
    }
  };







  const changeToken = async () => {
    try {
      const domain = window.location.hostname;
      const token = localStorage.getItem('userToken');
  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/newToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({ domain }) // Send the domain in the request body
      });
  
      if (response.ok) {
        fetchUserData(token, domain);
        Swal.fire({ icon: 'success', title: 'Success!', text: 'Token updated successfully' });
      } else {
        Swal.fire({ icon: 'error', title: 'Failed!', text: 'Failed to update Token' });
      }
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error!', text: 'Error updating Token' });
    }
  };
  






  
  
  const handleUserTypeUpgrade = async () => {
    try {
      const domain = window.location.hostname;
      const token = localStorage.getItem('userToken'); // Correctly define the token variable
  
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/user/Upgrade`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Send the token in the Authorization header
        },
        body: JSON.stringify({
          userCategory: selectedUserCategory,
          domain: domain,
        }),
      });
  
      if (response.ok) {
        Swal.fire({ icon: 'success', title: 'Success!', text: 'User type upgraded successfully' });
      } else {
        const errorData = await response.json();
        Swal.fire({ icon: 'error', title: 'Failed!', text: errorData.message || 'Failed to upgrade user type' });
      }
    } catch (error) {
      Swal.fire({ icon: 'error', title: 'Error!', text: 'Error upgrading user type' });
    }
  };
  


  
  if (isLoading) return <Loading />;

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
            Back
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>My Profile</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={8}>
            <Card className="mb-4">
              <Card.Body>
                <h4 className="mb-4">Update Profile</h4>
                <Form>
                  <Form.Group controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="phoneNumber">
                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="tel"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="oldTransactionPin">
                    <Form.Label>Old Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={oldTransactionPin}
                      onChange={(e) => setOldTransactionPin(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="newTransactionPin">
                    <Form.Label>New Transaction Pin</Form.Label>
                    <Form.Control
                      type="password"
                      value={newTransactionPin}
                      onChange={(e) => setNewTransactionPin(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="password">
                    <Form.Label>New Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </Form.Group>

                  <Form.Group controlId="confirmPassword">
                    <Form.Label>Confirm New Password</Form.Label>
                    <Form.Control
                      type="password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </Form.Group>

                  {formError && <div className="text-danger">{formError}</div>}

                  <Button className="mt-3" variant="primary" onClick={handleProfileUpdate}>
                    Update Profile
                  </Button>
                </Form>
                <hr />

<b>User Token</b>
<Form.Group controlId="token">
  <Form.Control
    type="text"
    value={myToken}
    readOnly
  />
</Form.Group>

<br />

<b>API Key</b>
<Form.Group controlId="apiKey">
  <Form.Control
    type="text"
    value={Token}
    readOnly
  />
</Form.Group>
<Button 
  variant="primary" 
  onClick={changeToken}
>
  Generate New API Key
</Button><br />
<p>If you suspect your token has been compromised, click the button below to generate a new token. Please note, generating a new token will invalidate your existing one, and all connected services will require updating to the new token accordingly.</p>

              </Card.Body>

              <hr />

              <Card.Body>
                <h4 className="mb-4">User Level</h4>
                <Form>
                  <Form.Group>
                    <Form.Label>User Category</Form.Label>
                    <Form.Control
                      as="select"
                      value={selectedUserCategory}
                      onChange={(e) => setSelectedUserCategory(e.target.value)}
                      required
                    >
                      <option value="Enduser">Enduser - Free</option>
                      <option value="agent">Agent - ₦{userTypePricing.agentUpgradeAmount}</option>
                      <option value="reseller">Reseller - ₦{userTypePricing.resellerUpgradeAmount}</option>
                      <option value="webOwner">Web Owner - ₦{userTypePricing.webOwnerUpgradeAmount}</option>
                    </Form.Control>
                  </Form.Group>

                  <Button className="mt-3" variant="success" onClick={handleUserTypeUpgrade}>
                    Upgrade
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DataPage;
