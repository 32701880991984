import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, Collapse, Modal } from 'react-bootstrap';
import { useAuth } from '../utils/auth';
import Swal from 'sweetalert2';

const SiteDataplan = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [botSvs, setbotSvs] = useState(false);
  const [showTelegramModal, setShowTelegramModal] = useState(false); // State for Telegram Modal
  const [telegramToken, setTelegramToken] = useState('');
  const [telegramLoading, setTelegramLoading] = useState(false); // Loading state for form
  const [telegramMessage, setTelegramMessage] = useState(''); // Message from backend

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');

    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
    }
  }, [navigate]);

  const validateToken = async (token) => {
    const domain = localStorage.getItem('domain');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/adminInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.clear();
        navigate('/');
      } else {
        setData(responseData.user);
      }
    } catch (error) {
      localStorage.clear();
      navigate('/');
    }
  };

  const handleTelegramModalClose = () => {
    setShowTelegramModal(false);
    setTelegramMessage(''); // Clear the message on close
  };
  const handleTelegramFormSubmit = async (e) => {
    e.preventDefault();
    setTelegramLoading(true); 
    setTelegramMessage(''); 
  
    const domain = localStorage.getItem('domain');
    const token = localStorage.getItem('userToken'); // Get token from localStorage
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/telegramUpdate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({
          telegramToken,
          domain,
        }),
      });
  
      if (!response.ok) {
        throw new Error('Invalid Telegram Bot Token');
      }
  
      const responseData = await response.json();
      setTelegramMessage(responseData.message); 
    } catch (error) {
      setTelegramMessage(error.message); 
    } finally {
      setTelegramLoading(false); 
    }
  };

  return (
    <Card className="mb-1">
      <Card.Header style={{ backgroundColor: 'black', textAlign: 'center', fontWeight: 'bold' }}>
        <Button
          variant="link"
          onClick={() => setbotSvs(!botSvs)}
          aria-controls="collapse-card-2"
          aria-expanded={botSvs}
          style={{ color: 'white' }}
        >
          Additional Services
        </Button>
      </Card.Header>
      <Collapse in={botSvs}>
        <Card.Body>
          <b>COMING SOON<hr /></b>
          <Button variant="primary" onClick={() => setShowTelegramModal(true)}>Connect Telegram Bot</Button> {/* Modal Button */}

          {/* Telegram Modal */}
          <Modal show={showTelegramModal} onHide={handleTelegramModalClose} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>Connect Telegram Bot Settings</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              {/* Instructions for Creating a Telegram Bot */}
              <p>
                To create a Telegram bot, follow these steps:
                <ol>
                  <li>Open Telegram and search for <strong>@BotFather</strong>.</li>
                  <li>Start a conversation with the BotFather.</li>
                  <li>Type <code>/newbot</code> and follow the instructions to create your bot. Input the Bot Token below</li>
                </ol>
              </p>

              {/* Telegram Bot Settings Form */}
              <Form onSubmit={handleTelegramFormSubmit}>
                <Form.Group controlId="telegramToken">
                  <Form.Label>Bot Token:</Form.Label>
                  <Form.Control
                    type="text"
                    name="telegramToken"
                    value={telegramToken}
                    onChange={(e) => setTelegramToken(e.target.value)}
                    required
                  />
                </Form.Group>

<br />
                {telegramMessage && <p style={{ color: telegramMessage.includes('successfully') ? 'green' : 'red' }}>{telegramMessage}</p>}

                <Button type="submit" variant="primary" disabled={telegramLoading}>
                  {telegramLoading ? 'Loading...' : 'Connect Bot'}
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default SiteDataplan;