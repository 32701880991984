import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { Navbar, Nav, Container, Form, Button, Card, Row, Col, Modal, Spinner } from 'react-bootstrap';
import { FaWhatsapp, FaSignInAlt, FaUserPlus, FaBolt, FaTv, FaMobileAlt, FaWifi, FaSms, FaShieldAlt, FaFastForward, FaRobot } from 'react-icons/fa';
import axios from 'axios';
import Footer from '../src/components/Footer';
import Loading from '../src/components/Loading';

import Swal from 'sweetalert2';



function App() {

  const hostname = window.location.hostname;


  const [expanded, setExpanded] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState('signIn');
  const [showForgotPasswordModal, setShowForgotPasswordModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showTransactionPin, setShowTransactionPin] = useState(false);
  const [siteName, setSiteName] = useState('');
  const [websiteSlogan, setwebsiteSlogan] = useState('');
  const [siteLogo, setsiteLogo] = useState('');
  const [siteAddress, setsiteAddress] = useState('');
  const [siteEmail, setsiteEmail] = useState('');
  const [sitePhone, setsitePhone] = useState('');
  const [aboutUs, setaboutUs] = useState('');
  const [heroImage, setheroImage] = useState('');
  const [aboutUsImage, setaboutUsImage] = useState('');
  const [aboutImage, setaboutImage] = useState('');
  const [homePagePriceDisplay, sethomePagePriceDisplaye] = useState('');
  const [textColor, settextColor] = useState('');
  const [backgroundColor, setbackgroundColor] = useState('');
  const [whatsappNumber, setwhatsappNumber] = useState('');
  const [pricingPlans, setPricingPlans] = useState([]);
  const [referredBy, setReferredBy] = useState('');



  useEffect(() => {
    const hostname = window.location.hostname;
    if (hostname.startsWith('www.')) {
      const newHost = hostname.replace('www.', '');
      window.location.replace(`https://${newHost}`); 
    }
  }, []);



  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const refValue = urlParams.get('ref');

    if (refValue) {
      setReferredBy(refValue);
    }
  }, []);






  
  useEffect(() => {
    const fetchDataPlans = async () => {
      const url = `${process.env.REACT_APP_BACKEND_URL}/app/dataPlan`; // Remove domain from URL

      try {
        const response = await axios.post(url, {}, { // Send empty body
          headers: {
            'Content-Type': 'application/json',
            'Domain': hostname // Pass domain as header
          }
        });

        if (response.data.status === 'success') {
          const dataPlans = response.data.filteredDataPlans.map(plan => ({
            title: `${plan.network} ${plan.planType}`,
            price: `₦${plan.Affiliate} / ${plan.dataSize}${plan.sizeType}`,
            validity: plan.validity,
            network: plan.network
          }));
          setPricingPlans(dataPlans);
          setIsLoading(false);

        } else {
          console.error('Error fetching data plans:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching data plans:', error);
      }
    };

    fetchDataPlans();
  }, []);



  useEffect(() => {
    const fetchSiteInfo = async () => {
      const domain = window.location.hostname;
      const url = `${process.env.REACT_APP_BACKEND_URL}/site/info`;

      try {
        const response = await axios.post(url, {}, { // Send an empty body
          headers: {
            'Content-Type': 'application/json',
            'Domain': hostname // Pass domain in the header
          }
        });

        if (response.data.siteName) {
          setSiteName(response.data.siteName);
          setwebsiteSlogan(response.data.websiteSlogan);
          setsiteAddress(response.data.address);
          setsiteEmail(response.data.siteEmail);
          setsitePhone(response.data.phoneNumber);
          setsiteLogo(response.data.siteLogo);      
          setaboutUs(response.data.aboutUs);
          setheroImage(response.data.heroImage);
          setaboutUsImage(response.data.aboutImage);
          settextColor(response.data.textColor);
          setbackgroundColor(response.data.backgroundColor);
          setwhatsappNumber(response.data.whatsappNumber);
          setaboutImage(response.data.aboutImage);
          sethomePagePriceDisplaye(response.data.homePagePriceDisplay);
        }
        setIsLoading(false);

      } catch (error) {
        console.error('Error fetching site info:', error);
      }
    };

    fetchSiteInfo();
  }, []);








  useEffect(() => {
    const verifyEmailFromURL = async () => {
      const params = new URLSearchParams(window.location.search);
      const token = params.get('verify');
      const domain = window.location.hostname;

      if (token) {
        setLoading(true); // Show loading modal
        await verifyEmail(token, domain);
        setLoading(false); // Hide loading modal
      }
    };

    verifyEmailFromURL();
  }, []);

  const verifyEmail = async (token, domain) => {
    try {
      const verifurl = `${process.env.REACT_APP_BACKEND_URL}/app/emailVerification`;
    
      const response = await axios.post(verifurl, { token, hostname });
      
      console.log('backend Response:', response);
      
      if (response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Email Verified',
          text: 'Your email has been successfully verified. You can now log in.',
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: 'Verification Failed',
          text: response.data.message || 'There was an issue with the verification.',
        });
      }
    } catch (error) {
      console.error('Verification failed:', error.response || error.message);
      
      Swal.fire({
        icon: 'error',
        title: 'Invalid Verification Link',
        text: 'The verification link is invalid or has expired.',
      });
    }
  };
  

  const handleNavClick = () => setExpanded(false);

  const handleShowModal = (type) => {
    setModalType(type);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    clearMessages();
  };

  const handleShowForgotPasswordModal = () => {
    setShowModal(false);
    setTimeout(() => setShowForgotPasswordModal(true), 600);
  };

  const handleCloseForgotPasswordModal = () => {
    setShowForgotPasswordModal(false);
    clearMessages();
  };

  const handleSubmit = async (e, type) => {
    e.preventDefault();
    setLoading(true);
    setResponseMessage('');
    setErrorMessage('');

    const form = e.target;
    const data = new FormData(form);
    const payload = Object.fromEntries(data.entries());
    payload.domain = window.location.hostname;

    const url = `${process.env.REACT_APP_BACKEND_URL}/oga/bossEnry`;
    try {
      const response = await axios.post(url, payload);
      setResponseMessage(response.data.message);

      if (type === 'login') {
        const token = response.data.token;
        const domain = response.data.domain;

        localStorage.setItem('userToken', token);
        localStorage.setItem('domain', domain);
        window.location.href = '/dashboard';
        return;
      }

      if (type === 'forgot-password') {
        return;
      }
    } catch (error) {
      setErrorMessage(error.response?.data?.message || 'Something went wrong!');
    } finally {
      setLoading(false);
      setTimeout(() => {
        clearMessages();
      }, 10000);
    }
  };

  const clearMessages = () => {
    setResponseMessage('');
    setErrorMessage('');
  };





  if (isLoading) {
    return <Loading />;
  }




  return (
    
    <div>
   




<div 
  className="hero-section" 
  style={{ 
    backgroundColor: 'black', 
    backgroundSize: 'cover', 
    backgroundPosition: 'center', 
    height: '500px', 
    width: '100vw', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    color: '#fff', 
    textAlign: 'center',
    margin: '0', 
    padding: '0' 
  }}>
  
  <Container style={{ textAlign: 'center' }}>
    Welcome to<br />
    <h1 style={{
      fontSize: '34px', 
      fontWeight: 'bold', 
      color: `#FFF`, 
      textShadow: `2px 2px 6px #000, -2px -2px 6px #000`
    }}>
      {siteName}
    </h1>

    <p style={{
      fontSize: '23px', 
      lineHeight: '1.5',  
      color: '#fff',   
      textShadow: `2px 2px 10px #000, -2px -2px 10px #000`
    }}>
WELCOME BACK    </p>

    <div style={{marginTop: '20px'}}>
      <Button 
        variant="primary" 
        onClick={() => handleShowModal('signIn')} 
        style={{
          backgroundColor: `${backgroundColor}`, 
          border: 'none', 
          padding: '15px 30px', 
          borderRadius: '20px', 
          marginRight: '10px', 
          fontSize: '18px', 
          color: `${textColor}`
        }}>
        <FaSignInAlt /> Sign In
      </Button>

 
    </div>
  </Container>
</div>


<Modal show={showModal} onHide={handleCloseModal} size="sm" centered style={{borderRadius: '10px'}}>
        <Modal.Header closeButton style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>
          <Modal.Title style={{fontSize: '20px', fontWeight: 'bold', backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>{modalType === 'signIn' ? 'Sign In' : 'Sign Up'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {modalType === 'signIn' ? (
<Form onSubmit={(e) => handleSubmit(e, 'login')}>
  <Form.Group controlId="formEmail">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Email</Form.Label>
    <Form.Control type="email" name="email" placeholder="Enter your email" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Form.Group controlId="formPassword">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Password</Form.Label>
    <Form.Control type={showPassword ? 'text' : 'password'} name="loginpassword" placeholder="Enter your password" required style={{padding: '10px', borderRadius: '5px'}} />
    <Button variant="link" onClick={() => setShowPassword(!showPassword)} style={{fontSize: '14px', color: `${backgroundColor}`}}>
      {showPassword ? 'Hide' : 'Show'}
    </Button>
  </Form.Group>
  <Button variant="primary" type="submit" disabled={loading} style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`, border: 'none', padding: '10px 20px', borderRadius: '20px', fontSize: '16px', width: '100%'}}>
    {loading ? 'Loading...' : 'Sign In'}
  </Button>
  {responseMessage && <p className="text-success mt-2" style={{fontSize: '14px'}}>{responseMessage}</p>}
  {errorMessage && <p className="text-danger mt-2" style={{fontSize: '14px'}}>{errorMessage}</p>}
</Form>
          ) : (
<Form onSubmit={(e) => handleSubmit(e, 'register')}>
  <Form.Group controlId="formFullName">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Full Name</Form.Label>
    <Form.Control name="fullName" type="text" placeholder="Enter full name" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Form.Group controlId="formEmail">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Email</Form.Label>
    <Form.Control type="email" name="email" placeholder="Enter email" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Form.Group controlId="formPhoneNumber">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Phone Number</Form.Label>
    <Form.Control type="number" name="phoneNumber" placeholder="Enter phone number" maxLength="11" minLength="11" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>


  <Form.Group controlId="formReferredBy">
                <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Referred By</Form.Label>
                <Form.Control 
                  type="text" 
                  name="referredBy" 
                  placeholder="Ref Code" 
                  style={{padding: '10px', borderRadius: '5px'}} 
                  value={referredBy}
                  readOnly={!!referredBy} // Make it read-only if referredBy has a value
                />
              </Form.Group>


  <Form.Group controlId="formPassword">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Password</Form.Label>
    <Form.Control type={showPassword ? 'text' : 'password'} name="password" placeholder="Enter your password" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Button variant="link" onClick={() => setShowPassword(!showPassword)} style={{fontSize: '14px', color: `${backgroundColor}`}}>
    {showPassword ? 'Hide' : 'Show'}
  </Button>
  <Form.Group controlId="formTransactionPin">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Transaction Pin</Form.Label>
    <Form.Control type={showTransactionPin ? 'text' : 'password'} name="transactionPin" placeholder="Enter 4 digits transaction pin" required style={{padding: '10px', borderRadius: '5px'}} />
  </Form.Group>
  <Button variant="link" onClick={() => setShowTransactionPin(!showTransactionPin)} style={{fontSize: '14px', color: `${backgroundColor}`}}>
    {showTransactionPin ? 'Hide' : 'Show'}
  </Button>





  <Form.Group controlId="formlocation">
  <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>Location</Form.Label>
  <Form.Control as="select" name="location" required style={{padding: '10px', borderRadius: '5px'}}>
    <option value="Abia">Abia</option>
    <option value="Adamawa">Adamawa</option>
    <option value="Akwa Ibom">Akwa Ibom</option>
    <option value="Anambra">Anambra</option>
    <option value="Bauchi">Bauchi</option>
    <option value="Bayelsa">Bayelsa</option>
    <option value="Benue">Benue</option>
    <option value="Borno">Borno</option>
    <option value="Cross River">Cross River</option>
    <option value="Delta">Delta</option>
    <option value="Ebonyi">Ebonyi</option>
    <option value="Edo">Edo</option>
    <option value="Ekiti">Ekiti</option>
    <option value="Enugu">Enugu</option>
    <option value="Gombe">Gombe</option>
    <option value="Imo">Imo</option>
    <option value="Jigawa">Jigawa</option>
    <option value="Kaduna">Kaduna</option>
    <option value="Kano">Kano</option>
    <option value="Katsina">Katsina</option>
    <option value="Kebbi">Kebbi</option>
    <option value="Kogi">Kogi</option>
    <option value="Kwara">Kwara</option>
    <option value="Lagos">Lagos</option>
    <option value="Nasarawa">Nasarawa</option>
    <option value="Niger">Niger</option>
    <option value="Ogun">Ogun</option>
    <option value="Ondo">Ondo</option>
    <option value="Osun">Osun</option>
    <option value="Oyo">Oyo</option>
    <option value="Plateau">Plateau</option>
    <option value="Rivers">Rivers</option>
    <option value="Sokoto">Sokoto</option>
    <option value="Taraba">Taraba</option>
    <option value="Yobe">Yobe</option>
    <option value="Zamfara">Zamfara</option>
    <option value="FCT">Federal Capital Territory (FCT)</option>
  </Form.Control>
</Form.Group>





  <Form.Group controlId="formHowDidYouHear">
    <Form.Label style={{fontSize: '16px', fontWeight: 'bold'}}>How Did You Hear About Us?</Form.Label>
    <Form.Control as="select" name="howDidYouHear" required style={{padding: '10px', borderRadius: '5px'}}>
      <option value="Facebook">Facebook</option>
      <option value="TikTok">TikTok</option>
      <option value="X">X</option>
      <option value="YouTube">YouTube</option>
      <option value="WhatsApp">WhatsApp</option>
      <option value="Friend">A Friend</option>
    </Form.Control>
  </Form.Group>

<br />

  <Form.Group controlId="formTerms">
    <Form.Check type="checkbox" label="I agree to the terms and conditions" required style={{fontSize: '14px'}} />
  </Form.Group>
  <br />
  {responseMessage && <p className="text-success mt-2" style={{fontSize: '14px'}}>{responseMessage}</p>}
  {errorMessage && <p className="text-danger mt-2" style={{fontSize: '14px'}}>{errorMessage}</p>}
  <Button variant="primary" type="submit" disabled={loading} style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`, border: 'none', padding: '10px 20px', borderRadius: '20px', fontSize: '16px'}}>
    {loading ? 'Loading...' : 'Sign Up'}
  </Button>


</Form>
          )}
        </Modal.Body>
        <Modal.Footer style={{backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>
          {modalType === 'signIn' && (
<Button variant="link" onClick={handleShowForgotPasswordModal} style={{fontSize: '14px', backgroundColor: `${backgroundColor}`, color: `${textColor}`}}>Forgot Password?</Button>
          )}
        </Modal.Footer>
      </Modal>




  <br /> <br />
    </div>

  );
}



export default App;