import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Collapse, Table, Modal, Form } from 'react-bootstrap';
import { useAuth } from '../utils/auth';
import Swal from 'sweetalert2';

const SiteDataplan = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [isOpen1, setIsOpen1] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isAlpha, setIsAlpha] = useState(null); // State for isAlpha

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      fetchData(token);
      fetchApiKeys(); // Fetch isAlpha on component mount
    }
  }, [navigate]);

  const fetchData = async (token) => {
    const domain = localStorage.getItem('domain');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/alphaDataPlans`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ domain }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch data plans');
      }
      const responseData = await response.json();
      if (responseData.status === 'success') {
        setData(responseData.data);
      } else {
        throw new Error('Failed to fetch data plans');
      }
    } catch (error) {
      console.error('Error fetching data plans:', error);
    }
  };

  const handleOptionClick = (plan) => {
    setModalData(plan);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const fetchApiKeys = async () => {
    const domain = localStorage.getItem('domain');
    const token = localStorage.getItem('userToken');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/adminSiteInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'Domain': domain,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch API keys');
      }

      const responseData = await response.json();
      if (responseData.success) {
        const keys = {};
        responseData.rows.forEach((row) => {
          keys[row.apiName] = row.apiKey;
        });
        setIsAlpha(responseData.rows[0].isAlpha);
      } else {
        // Handle the case where success is false
      }
    } catch (error) {
      // Handle the error appropriately (e.g., display an error message)
    }
  };

  const validateToken = async (token) => {
    const domain = window.location.hostname;
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
      } else {
        setData(responseData.user);
      }
    } catch (error) {
      localStorage.removeItem('userToken');
      navigate('/');
    }
  };


  





  const handleFormSubmit = async (formData) => {
    try {
      const token = localStorage.getItem('userToken');
      const domain = localStorage.getItem('domain');
      formData = { ...formData, domain };
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/importDataPlan`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify(formData),
      });
      if (!response.ok) {
        throw new Error('Failed to update data plan');
      }
      fetchData(localStorage.getItem('userToken'));
      setShowModal(false);

      // Show SweetAlert2 success message
      Swal.fire({
        title: 'Success!',
        text: 'Data Plan successfully added to your website. Refresh and click on My Data Plan to confirm.',
        icon: 'success',
        timer: 1000, // Auto close after 2 seconds
        timerProgressBar: true, // Show timer progress bar
        showConfirmButton: true, // Allow OK button to close
      });

    } catch (error) {
      alert('Failed to update data plan. Please try again.');
    }
  };









  const getAvailabilityStatus = (plan) => {
    const ids = [
      { name: 'vtu9ja', value: plan.vtu9ja_id },
      { name: 'salabeb', value: plan.salabeb_id },
      { name: 'datastation', value: plan.datastation_id },
      { name: 'dancitysub', value: plan.dancitysub_id },
      { name: 'clubkonnect', value: plan.clubkonnect_Id },
      { name: 'bulksmsnigeria', value: plan.bulksmsnigeria_Id },
      { name: 'ibrolinks', value: plan.ibrolinks_Id },
    ];

    return ids.map((id) => (
      `${id.name}${id.value === '0' ? '❌' : '✅'}`
    )).join(' || ');
  };

  return (
    <Card className="mb-4">
      <Card.Header style={{ backgroundColor: 'green', textAlign: 'center', fontWeight: 'bold' }}>
        <Button variant="link" onClick={() => setIsOpen1(!isOpen1)} aria-controls="collapse-card-2" aria-expanded={isOpen1} style={{ color: 'white' }}>
          Global Data Plans (Available to be imported to your site)
        </Button>
      </Card.Header>
      <Collapse in={isOpen1}>
        <Card.Body>
          <Table striped bordered hover responsive>
            <thead>
              <tr>
                <th>Network</th>
              </tr>
            </thead>
            <tbody>
              {data &&
                data.map((plan) => (
                  <tr key={plan.id}>
                    <td>
                      <h5>{plan.network} {plan.planType}</h5>
                      <b>{plan.dataSize}{plan.sizeType}</b><br />
                      <small>{plan.validity}</small>
                      <hr />
                      {/* Show this section only when isAlpha is true (1) */}
                      {isAlpha === 1 && (
                        <>
                          <small>{getAvailabilityStatus(plan)}</small><br />
                        </>
                      )}
                      <Button variant="secondary" className="w-100 mb-4" onClick={() => handleOptionClick(plan)}>
                        Import to Site 
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>

          <Modal show={showModal} onHide={handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>
                {modalData ? `Import ${modalData.dataSize}${modalData.sizeType} ${modalData.network} ${modalData.planType}` : 'Loading...'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  const formData = {
                    ...modalData,
                    endUserAmount: e.target.endUserAmount.value,
                    planType: e.target.planType.value,
                    agentAmount: e.target.agentAmount.value,
                    resellerAmount: e.target.resellerAmount.value,
                    webOwnerAmount: e.target.webOwnerAmount.value,
                    action: e.target.action.value,
                  };
                  handleFormSubmit(formData);
                }}
              >
                <p>
                <ol>
  <li>Before adding this plan, confirm the selling price from your host site to prevent potential losses.</li>
  <li>The default pricing for this plan is set to 900,000. After adding the plan, you must <b>update the price for 1GB</b> in this category under <b>My Data Plan</b> Section!, which will automatically adjust the pricing for other plan sizes based on the 1GB price.</li>
  <li>Ensure this specific data plan is available on the selected host site to avoid errors.</li>
</ol>


                </p>
                <Form.Group controlId="endUserAmount">
                  <Form.Control type="hidden" name="endUserAmount" value="10500" required />
                </Form.Group>
                  <Form.Control type="hidden" name="planType" value={modalData ? modalData.planType : ''} />

                <Form.Group controlId="agentAmount">
                  <Form.Control type="hidden" value="10500" name="agentAmount" required />
                </Form.Group>
                <Form.Group controlId="resellerAmount">
                  <Form.Control type="hidden" value="10500" name="resellerAmount" required />
                </Form.Group>
                <Form.Group controlId="webOwnerAmount">
                  <Form.Control type="hidden" value="10500" name="webOwnerAmount" required />
                </Form.Group>
                <Form.Group controlId="action">
                  <Form.Label>Action</Form.Label>
                  <Form.Control as="select" name="action" defaultValue="Activate" required>
                    <option value="1">Activate</option>
                    <option value="0">Deactivate</option>
                  </Form.Control>
                </Form.Group>
                <br />
                <Button variant="primary" type="submit">
                  Import to site
                </Button>
              </Form>
            </Modal.Body>
          </Modal>
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default SiteDataplan;