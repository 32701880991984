import React, { useState, useEffect } from 'react';
import { Card, Button, Collapse, Table, InputGroup, FormControl, Pagination } from 'react-bootstrap';
import { useAuth } from '../utils/auth';

const AllOrderHistory = () => {
  const [todayOrders, setTodayOrders] = useState([]);
  const [filteredAllOrders, setFilteredAllOrders] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [ordersPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenAll, setIsOpenAll] = useState(false);

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (token) {
      fetchOrderHistoryToday(token);
    }
  }, []);

  const fetchOrderHistoryToday = async (token) => {
    setIsLoading(true);
    const domain = localStorage.getItem('domain');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/adminordsToday`, {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ domain }),
      });
      if (!response.ok) {
        throw new Error('Failed to fetch order history');
      }
      const responseData = await response.json();
      if (responseData.status === 'success') {
        setTodayOrders(responseData.orders);
        setFilteredAllOrders(responseData.orders);
      }
    } catch (error) {
      console.error('Error fetching order history:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    if (value) {
      setFilteredAllOrders(todayOrders.filter(order => 
        order.orderId.toLowerCase().includes(value.toLowerCase()) ||
        order.email.toLowerCase().includes(value.toLowerCase()) ||
        order.service.toLowerCase().includes(value.toLowerCase())
      ));
    } else {
      setFilteredAllOrders(todayOrders);
    }
    setCurrentPage(1); // Reset to first page when searching
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // Pagination Logic for All Order History Card
  const indexOfLastAllOrder = currentPage * ordersPerPage;
  const indexOfFirstAllOrder = indexOfLastAllOrder - ordersPerPage;
  const currentAllOrders = filteredAllOrders.slice(indexOfFirstAllOrder, indexOfLastAllOrder);

  const allPageNumbers = [];
  for (let i = 1; i <= Math.ceil(filteredAllOrders.length / ordersPerPage); i++) {
    allPageNumbers.push(i);
  }

  return (
    <Card className="mb-1">
      <Card.Header style={{ backgroundColor: 'green', textAlign: 'center', fontWeight: 'bold' }}>
        <Button variant="link" onClick={() => setIsOpenAll(!isOpenAll)} aria-controls="collapse-card-3b" aria-expanded={isOpenAll} style={{ color: 'white' }}>
          All Order History
        </Button>
      </Card.Header>
      <Collapse in={isOpenAll}>
        <Card.Body>
          {isLoading && (
            <div className="text-center">Loading...</div>
          )}
          {!isLoading && (
            <div>
              <InputGroup className="mb-3">
                <FormControl
                  placeholder="Search Orders"
                  aria-label="Search Orders"
                  value={searchTerm}
                  onChange={handleSearch}
                />
              </InputGroup>
              <Table striped bordered hover responsive>
                <thead>
                  <tr>
                    <th>Order ID</th>
                    <th>Service</th>
                    <th>Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {currentAllOrders.map((order) => (
                    <tr key={order.orderId}>
                      <td>ID: {order.orderId}<br /><small>{order.email}</small></td>
                      <td><b>{order.service}</b><br /><small>{order.date}</small></td>
                      <td><small>₦{order.amount}<br />
                      OB: {order.oldBal}<br />
                      NB: {order.newBal}
                      </small></td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination>
                {allPageNumbers.map(number => (
                  <Pagination.Item
                    key={number}
                    active={number === currentPage}
                    onClick={() => handlePageChange(number)}
                  >
                    {number}
                  </Pagination.Item>
                ))}
              </Pagination>
            </div>
          )}
        </Card.Body>
      </Collapse>
    </Card>
  );
};

export default AllOrderHistory;