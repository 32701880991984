import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, Button, Collapse, Table, Modal, Form, Row, Col, Spinner, Pagination } from 'react-bootstrap';
import { useAuth } from '../utils/auth';

const MyDataP = () => {
  const navigate = useNavigate();
  const [siteData, setSiteData] = useState([]);
  const [isOpenUsers, setIsOpenUsers] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false); // Loading state for modal buttons
  const [responseMessage, setResponseMessage] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [randomValue, setRandomValue] = useState('');

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      fetchSiteDataPlans(token);
    }
  }, [navigate]);

  useEffect(() => {
    const generateRandomAlphanumeric = (length) => {
      const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      let result = '';
      for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        result += characters[randomIndex];
      }
      return result;
    };

    setRandomValue(generateRandomAlphanumeric(4));
  }, []);

  const fetchSiteDataPlans = async (token) => {
    const domain = localStorage.getItem('domain');
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/siteUsersAdmin`, {
        method: 'POST',
        headers: { 
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'domain': domain
        },
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const responseData = await response.json();
      if (responseData.status === 'success' && Array.isArray(responseData.users)) {
        setSiteData(responseData.users);
      } else {
        console.error('Unexpected response data:', responseData);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const handleEditClick = async (userID) => {
    const token = localStorage.getItem('userToken');
    const domain = localStorage.getItem('domain');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/backOfficeUserUpd`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userID })
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }
      const userData = await response.json();
      if (userData.status === 'success') {
        setSelectedUser(userData.user);
        setShowModal(true);
      } else {
        console.error('Error fetching user data:', userData.message);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };






const handleDeleteUser = async () => {
  setLoading(true); 
  const token = localStorage.getItem('userToken');
  const domain = localStorage.getItem('domain');

  try {
    const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/AdminUserDelete`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ userID: selectedUser.id, domain })
    });

    // Check for both status code AND response.ok (which checks for other network errors)
    if (!response.ok) {
      const errorData = await response.json(); // Try to get error data from backend
      setResponseMessage(errorData.message || 'Failed to delete user'); 
      // Use errorData.message if available, otherwise default to generic message
    } else {
      const result = await response.json();
      setResponseMessage(result.message); // Use the backend's success/failure message

      if (result.status === 'success') {
        fetchSiteDataPlans(token);
        handleCloseModal();
      } else {
        // The status might be 'error' even if response.ok is true (for example, if the user was not found)
        console.error('Error deleting user:', result.message); 
      }
    }
  } catch (error) {
    console.error('Error deleting user:', error);
    setResponseMessage('Error deleting user'); 
  } finally {
    setLoading(false); 
  }
};






  const handleCloseModal = () => {
    setSelectedUser(null);
    setShowModal(false);
    setResponseMessage('');
  };

  const handleUpdateWallet = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem('userToken');
    const walletAmount = e.target.wallet.value;
    const walletType = e.target.walletType.value;
    const userEmail = selectedUser.tani;
    const domain = localStorage.getItem('domain');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/BackOfficeFunding`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ userEmail, walletAmount, walletType, domain })
      });

      const result = await response.json();
      setResponseMessage(result.message);

      if (result.status === 'success') {
        fetchSiteDataPlans(token);
        handleCloseModal();
        alert(responseMessage); // Show alert here
      } else {
        console.error('Error:', result.message);
        alert(responseMessage); // Show alert here
      }
    } catch (error) {
      console.error('Error updating wallet:', error);
      setResponseMessage('Error updating wallet');
      alert(responseMessage); // Show alert here
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateAccount = async (e) => {
    e.preventDefault();
    setLoading(true);
    const token = localStorage.getItem('userToken');
    const formData = {
      userEmail: e.target.userEmail.value,
      userId: e.target.userId.value,
      fulName: e.target.fulName.value,
      orderLimit: e.target.orderLimit.value,
      userCategory: e.target.userCategory.value,
      userStatus: e.target.userStatus.value,
      emailVerificationStatus: e.target.emailVerificationStatus.value,
      password: e.target.password.value
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/AdminUserDetailUpdate`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      const result = await response.json();
      setResponseMessage(result.message);

      if (result.status === 'success') {
        alert(responseMessage); 
        fetchSiteDataPlans(token);
        handleCloseModal();
      } else {
        console.error('Error:', result.message);
        alert(responseMessage); // Show alert here after error
      }

    } catch (error) {
      setResponseMessage('Error updating account');
      alert(responseMessage); // Show alert here if an error occurs during fetch
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const filteredSiteData = siteData.filter(user => 
    user.fu_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.tani.toLowerCase().includes(searchTerm.toLowerCase()) ||
    user.phoneNumber.includes(searchTerm)
  );

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredSiteData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredSiteData.length / itemsPerPage);

  return (
    <Card className="mb-1">
      <Card.Header style={{ backgroundColor: 'green', textAlign: 'center', fontWeight: 'bold' }}>
        <Button variant="link" onClick={() => setIsOpenUsers(!isOpenUsers)} aria-controls="collapse-card-3" aria-expanded={isOpenUsers} style={{ color: 'white' }}>
          Users Lists
        </Button>
      </Card.Header>
      <Collapse in={isOpenUsers}>
        <Card.Body>
          <Form.Control
            type="text"
            placeholder="Search users..."
            value={searchTerm}
            onChange={handleSearch}
            className="mb-3"
          />
          {currentItems.length === 0 ? (
            <p>No users found.</p>
          ) : (
            <>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Info</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map(user => (
                    <tr key={user.id}>
                      <td>{user.fu_name}<br />
                      {user.tani}<br />
                      ₦{parseFloat(user.owomi).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                      <br />
                      {user.phoneNumber}<br />
                      </td>
                      <td width="50%">
                        User Level: {user.userType}<br />
                        Last Seen: {user.lastLogin}<br />
                        Status: {user.active === 1 ? 'Active' : 'Banned'}<br />
                        {user.verified === '1' ? '' : 'Unverified'}
                        <br />
                        <Button onClick={() => handleEditClick(user.id)} disabled={loading}>OPEN</Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Pagination>
                <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
                {Array.from({ length: totalPages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === currentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} />
              </Pagination>
            </>
          )}
        </Card.Body>
      </Collapse>
      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Edit User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedUser && (
            <Row>
              <Col lg={12}>
                <center>
                  <h2>
                    {selectedUser.fu_name}<br />
                    Wallet: ₦{parseFloat(selectedUser.owomi).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                  </h2>
                  <hr />
                </center>
              </Col>
              <Col md={6}>
                <Form onSubmit={handleUpdateWallet}>
                  <Form.Group>
                    <Form.Label>Wallet Amount</Form.Label>
                    <Form.Control type="text" name="wallet" placeholder="Enter amount" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Wallet Type</Form.Label>
                    <Form.Control as="select" name="walletType">
                      <option>Credit</option>
                      <option>Debit</option>
                    </Form.Control>
                  </Form.Group><br />
                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Update Wallet'}
                  </Button>
                </Form>
              </Col>
              <Col md={6}>
                <Form onSubmit={handleUpdateAccount}>
                  <Form.Group>
                    <Form.Label>Full Name</Form.Label>
                    <Form.Control type="text" defaultValue={selectedUser.fu_name} name="fulName" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email</Form.Label>
                    <Form.Control type="email" defaultValue={selectedUser.tani} name="userEmail" />
                    <Form.Control type="hidden" defaultValue={selectedUser.randId} name="userId" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Order Limit</Form.Label>
                    <Form.Control type="number" defaultValue={selectedUser.orderLimit} name="orderLimit" />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>User Level</Form.Label>
                    <Form.Control as="select" defaultValue={selectedUser.userType} name="userCategory">
                      <option value={selectedUser.userType}>{selectedUser.userType}</option>
                      <option>EndUser</option>
                      <option>Agent</option>
                      <option>Reseller</option>
                      <option>WebOwner</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>User Status</Form.Label>
                    <Form.Control as="select" defaultValue={selectedUser.userStatus} name="userStatus">
                      <option value="1">UnBan User</option>
                      <option value="0">Ban User</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Email Verification Status</Form.Label>
                    <Form.Control as="select" defaultValue={selectedUser.emailVerificationStatus} name="emailVerificationStatus">
                      <option value="1">Auto Verify</option>
                      <option value={randomValue}>Mark Unverified</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Password</Form.Label>
                    <Form.Control type="password" placeholder="Enter new password" name="password" autocomplete="off" />
                  </Form.Group>
                  <Button variant="primary" type="submit" className="mt-4" disabled={loading}>
                    {loading ? <Spinner animation="border" size="sm" /> : 'Update Account'}
                  </Button>
                </Form>
              </Col>
            </Row>
          )}
          <hr />
          <Button variant="danger" onClick={handleDeleteUser} disabled={loading}>
            {loading ? <Spinner animation="border" size="sm" /> : 'Delete User'}
          </Button>
        </Modal.Body>
      </Modal>
    </Card>
  );
};

export default MyDataP;