import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Modal, Form, Alert } from 'react-bootstrap';
import { useAuth } from '../utils/auth';

const WebsiteSettings = () => {
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false);
  const [settings, setSettings] = useState({
    siteName: '',
    websiteSlogan: '',
    aboutUs: '',
    phoneNumber: '',
    textColor: '',
    backgroundColor: '',
    referralBonusAmount: '',
    whatsappNumber: '',
    whatsappGroupLink: '',
    address: '',
    airtimeStatus: '',
    airtimePercentage: '',
    electricApiKey: '',
    electricCharge: '',
    electricApi: '',
    electricStatus: '',
    sms: '',
    smsPrice: '',
    manualFundingAccountDefault: '',
    agentUpgradeAmount: '',
    resellerUpgradeAmount: '',
    webOwnerUpgradeAmount: '',
    newUserOrderLimit: '',
    newRegistrationEmailVerification: '',
    enableReg: '',
    heroImgWriteup: '',
    monnify: '',
    monnifyApiKey: '',
    monnifySecretKey: '',
    monnifyContractCode: '',
    monnifyChargesType: '',
    monnifyChargesValue: '',
    payvessle: '',
    payvessleBusinessId: '',
    payvessleApiKey: '',
    payvessleSecretKey: '',
    payvessleChargesType: '',
    payvessleChargesValue: '',
    korapay: '',
    korapayPublicKey: '',
    korapaySecretKey: '',
    korapayChargesType: '',
    korapayChargesValue: '',
    vpay: '',
    vpayPublicKey: '',
    vpaySecretKey: '',
    vpayChargesType: '',
    vpayChargesValue: '',
    popUpMessage: '',
    siteEmail: '',
    dashboardBG: '',
    dashboardColor: '',
  });

  const [uploadStatus, setUploadStatus] = useState('');
  
  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    if (!token) {
      navigate('/');
    } else {
      fetchAdminSiteInfo(token);
    }
  }, [navigate]);

  const fetchAdminSiteInfo = async (token) => {
    try {
      const domain = localStorage.getItem('domain');

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/adminSiteInfo`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          domain: domain,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to fetch site info');
      }

      const responseData = await response.json();
      if (responseData.success) {
        const siteInfo = responseData.rows[0];
        setSettings({
          ...siteInfo,
          heroImage: '',
          aboutImage: '',
        });
      } else {
        throw new Error('Failed to fetch site info');
      }
    } catch (error) {
      console.error('Error fetching site info:', error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('userToken');

    try {
      const formData = new FormData();
      for (const key in settings) {
        if (key !== 'heroImage' && key !== 'aboutImage') {
          formData.append(key, settings[key]);
        }
      }
      const domain = localStorage.getItem('domain');

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/RegSetupUpd`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          domain: domain,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Failed to update settings');
      }

      alert('Settings updated successfully');
      setShowModal(false);
    } catch (error) {
      alert('Failed to update settings. Please try again.');
    }
  };




  const handleFileUpload = async (fileInput) => {
    const formData = new FormData();
    const fileName = fileInput.name;
    const file = fileInput.files[0];
    const domain = localStorage.getItem('domain');

    formData.append(fileName, file);
    formData.append('domain', domain);
    const token = localStorage.getItem('userToken');
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/uploadImage`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: formData,
      });
  
      const data = await response.json();
      alert(data.message);
    } catch (error) {
      setUploadStatus('Failed to upload image');
      alert('Failed to upload image, Please make sure the it`s a valid image file and it`s less than 500kb');

    }
  };

  const handleChange = (e) => {
    const { name, value, files } = e.target;
  
    if (files && files.length > 0) {
      handleFileUpload(e.target); // Pass the file input element to handleFileUpload
    } else {
      setSettings((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  









  const handleCloseModal = () => {
    setShowModal(false);
  };


  return (
    <>
      <center>
        <Button
          variant="primary"
          onClick={() => setShowModal(true)}
          style={{ width: '100%', backgroundColor: 'blue', borderColor: 'blue' }}
        >
          Site Settings
        </Button>
      </center>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Website Settings</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {uploadStatus && <Alert variant="info">{uploadStatus}</Alert>}
          <Form onSubmit={handleFormSubmit}>
            {Object.keys(settings).map((key) => {
              if (key === 'newRegistrationEmailVerification' || key === 'sms' || key === 'enableReg' || key === 'monnify' || key === 'payvessle' || key === 'korapay' || key === 'vpay' || key === 'airtimeStatus' || key === 'electricStatus' || key === 'providus' || key === 'homePagePriceDisplay' || key === 'dashboardPagePriceDisplay') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Label>{key.replace(/([A-Z])/g, ' $1')}</Form.Label>
                    <Form.Control
                      as="select"
                      name={key}
                      value={settings[key]}
                      onChange={handleChange}
                    >
                      <option>Select</option>
                      <option value="1">Enable</option>
                      <option value="0">Disable</option>
                    </Form.Control>
                  </Form.Group>
                );
              }

              if (key === 'monnifyChargesType') {
                return (
                  <Form.Group controlId={key} key={key}>
                  <Form.Label>{key.replace(/([A-Z])/g, ' $1')}</Form.Label>
                  <Form.Control
                    as="select"
                    name={key}
                    value={settings[key]}
                    onChange={handleChange}
                  >
                    <option>Select</option>
                    <option value="1">Flat</option>
                    <option value="0">Percentage</option>
                  </Form.Control>
                </Form.Group>
                );
              }


              if (key == 'telegramUsername' || key == 'telegramStatus' || key == 'telegramToken' || key == 'siteEmail' || key == 'ibrolinks' || key =='ibrolinksPin' || key =='ibrolinksSecrete' || key === 'domain' || key === 'id' || key === 'isAlpha' || key === 'motherSite' || key === 'apiKey' || key === 'heroImgWriteup' || key === 'bulksmsnigeria' || key === 'airtimeApi' || key === 'airtimeApiKey' || key === 'electricApi' || key ==='electricApiKey' || key ==='examApi'  || key ==='cableApi' || key ==='customEmail'  || key ==='customEmailPass' || key === 'customMailHot' || key === 'customMailPort' || key === 'examApiKey' ||  key === 'smsApiKey' || key === 'cableApiKey' || key ==='vtu9ja' || key ==='salabeb' || key === 'datastation' || key ==='clubkonnect' || key ==='clubkonnectUserId' || key ==='smsApi') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Control
                      type="hidden"
                      name={key}
                      value={settings[key]}
                      onChange={handleChange}
                    />
                  </Form.Group>
                );
              }

              if (key == 'dancitysub' || key == 'ibrolinks' || key =='ibrolinksPin' || key =='ibrolinksSecrete' || key === 'domain' || key === 'id' || key === 'isAlpha' || key === 'motherSite' || key === 'apiKey' || key === 'heroImgWriteup' || key === 'bulksmsnigeria' || key === 'airtimeApi' || key === 'airtimeApiKey' || key === 'electricApi' || key ==='electricApiKey' || key ==='examApi'  || key ==='cableApi' || key ==='customEmail'  || key ==='customEmailPass' || key === 'customMailHot' || key === 'customMailPort' || key === 'examApiKey' ||  key === 'smsApiKey' || key === 'cableApiKey' || key ==='vtu9ja' || key ==='salabeb' || key === 'datastation' || key ==='clubkonnect' || key ==='clubkonnectUserId' || key ==='smsApi') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Control
                      type="hidden"
                      name={key}
                      value={settings[key]}
                      onChange={handleChange}
                    />
                  </Form.Group>
                );
              }
              if (key === 'textColor' || key === 'backgroundColor') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Label>Landing Page {key.replace(/([A-Z])/g, ' $1')}</Form.Label>
                    <Form.Control
                      type="color"
                      name={key}
                      value={settings[key]} // Display the current color value
                      onChange={handleChange}
                    />
                  </Form.Group>
                );
              }


              if (key === 'dashboardBG' || key === 'dashboardColor') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Label>{key.replace(/([A-Z])/g, ' $1')}</Form.Label>
                    <Form.Control
                      type="color"
                      name={key}
                      value={settings[key]} // Display the current color value
                      onChange={handleChange}
                    />
                  </Form.Group>
                );
              }


              if (key === 'referralBonusAmount') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Label>Referral Bonus Percentage. This is the amount user will keep getting when their referee fund their wallet. This can be as low as 0.01</Form.Label>
                    <Form.Control
                      type="text"
                      value={settings[key]} // Display the current color value

                      name={key}
                      onChange={handleChange}
                    />
                  </Form.Group>
                );
              }
              if (key === 'smsPrice' || key === 'phoneNumber' || key === 'whatsappNumber' || key === 'airtimePercentage' || key === 'electricCharge' || key === 'agentUpgradeAmount' || key === 'resellerUpgradeAmount' || key === 'webOwnerUpgradeAmount' || key === 'newUserOrderLimit') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Label>{key.replace(/([A-Z])/g, ' $1')}</Form.Label>
                    <Form.Control
                      type="number"
                      value={settings[key]} // Display the current color value

                      name={key}
                      onChange={handleChange}
                    />
                  </Form.Group>
                );
              }
              if (key === 'heroImage') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Label>Site Banner. only PNG is allowed!</Form.Label>
                    <Form.Control
                      type="file"
                      name={key}
                      onChange={handleChange}
                      accept="image/png"
                    />
                  </Form.Group>
                );
              }

              if (key === 'aboutImage' || key === 'siteLogo' || key ==='aboutUsImage') {
                return (
                  <Form.Group controlId={key} key={key}>
                    <Form.Label>{key.replace(/([A-Z])/g, ' $1')}</Form.Label>
                    <Form.Control
                      type="file"
                      name={key}
                      onChange={handleChange}
                      accept="image/*"
                    />
                  </Form.Group>
                );
              }
              if (key === 'heroImgWriteup' || key === 'aboutUs' || key === 'manualFundingAccountDefault' || key === 'popUpMessage') {
                return (
                  <Form.Group controlId={key} key={key}>
                  <Form.Label>{key.replace(/([A-Z])/g, ' $1')}</Form.Label>
                  <Form.Control
                      as="textarea"
                      name={key}
                      value={settings[key]} // Set the current value
                      onChange={handleChange}
                      rows={3} // Adjust the number of rows as needed
                    />
                  </Form.Group>
                );
              }
              
              
              return (
                <Form.Group controlId={key} key={key}>
                  <Form.Label>{key.replace(/([A-Z])/g, ' $1')}</Form.Label>
                  <Form.Control
                    type="text"
                    name={key}
                    value={settings[key]}
                    onChange={handleChange}
                  />
                </Form.Group>
              );
            })}
            <center><br />
              <Button
                variant="primary"
                type="submit"
                style={{ width: '100%', backgroundColor: 'blue', borderColor: 'blue' }}
              >
                Save Settings
              </Button>
            </center>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default WebsiteSettings;
