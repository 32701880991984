import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, Collapse, Modal } from 'react-bootstrap';
import { useAuth } from '../utils/auth';
import Loading from '../components/Loading';

const UniversalData = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    network: '',
    planType: '',
    dataSize: '',
    sizeType: '',
    validity: '',
    companyPrice: '',
    salabebId: '',
    vtu9jaId: '',
    datastationId: '',
    dancitysuId: '',
    clubkonnectId: '',
    bulksmsNg: '',
    ibrolinksId: '',
  });
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState(null);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpenTg, setIsOpenTg] = useState(false);
  const [isOpenNewAcn, setIsOpenNewAcn] = useState(false); // State for New Account modal

  const [showActivateModal, setShowActivateModal] = useState(false); // Modal for user activation
  const [activationDomain, setActivationDomain] = useState('');
  const [activationLoading, setActivationLoading] = useState(false); // Loading state for activation
  const [activationMessage, setActivationMessage] = useState(''); // Message from backend

  const [newSiteData, setNewSiteData] = useState({
    domain: '',
    siteName: '',
    siteEmail: '',
    motherSite: '',
    apiKey: '',
    phoneNumber: '',
    customEmail: '',
    customEmailPass: '',
    customMailHot: '',
    customMailPort: '',
    textColor: '',
    backgroundColor: '',
    dashboardBG: '',
    dashboardColor: '',
    isAlpha: '',
  });
  const [newSiteLoading, setNewSiteLoading] = useState(false); // Loading state for new site creation
  const [newSiteMessage, setNewSiteMessage] = useState(''); // Message from backend

  useAuth();

  useEffect(() => {
    const token = localStorage.getItem('userToken');

    if (!token) {
      navigate('/');
    } else {
      validateToken(token);
    }
  }, [navigate]);

  const handleBackClick = () => {
    navigate('/dashboard');
  };

  const validateToken = async (token) => {
    const domain = localStorage.getItem('domain');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/adminInfo`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to validate token');
      }

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.clear();
        navigate('/');
      } else {
        setData(responseData.user); // Set user data to state
        setIsLoading(false);
      }
    } catch (error) {
      localStorage.clear();
      navigate('/'); // Redirect to login on error
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleNewSiteChange = (e) => {
    const { name, value } = e.target;
    setNewSiteData({
      ...newSiteData,
      [name]: value
    });
  };

  const handleSubmit = async (e, formData, setFormData) => {
    e.preventDefault();
    
    try {
      const token = localStorage.getItem('userToken');

      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/alphaData`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ ...formData, domain: window.location.hostname }),
      });

      if (!response.ok) {
        throw new Error('Failed to add data plan');
      }

      // Reset form after successful submission
      setFormData({
        network: '',
        planType: '',
        dataSize: '',
        sizeType: '',
        validity: '',
        companyPrice: '',
        salabebId: '',
        vtu9jaId: '',
        datastationId: '',
        dancitysuId: '',
        clubkonnectId: '',
        bulksmsNg: '',
        ibrolinksId: ''
      });

      alert('Data plan added successfully!');
    } catch (error) {
      console.error('Error adding data plan:', error);
      alert('Failed to add data plan. Please try again.');
    }
  };

  const handleActivateModalClose = () => {
    setShowActivateModal(false);
    setActivationMessage('');
  };

  const handleActivateFormSubmit = async (e) => {
    e.preventDefault();
    setActivationLoading(true);
    setActivationMessage('');

    const token = localStorage.getItem('userToken');

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/boss/telegramActivation`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ domain: activationDomain }),
      });

      if (!response.ok) {
        throw new Error('Failed to activate user');
      }

      const responseData = await response.json();
      setActivationMessage(responseData.message);
    } catch (error) {
      console.error('Error activating user:', error);
      setActivationMessage('Error activating user. Please try again.');
    } finally {
      setActivationLoading(false);
    }
  };

  const handleNewSiteModalClose = () => {
    setShowActivateModal(false);
    setNewSiteMessage('');
  };

  const handleNewSiteFormSubmit = async (e) => {
    e.preventDefault();
    setNewSiteLoading(true);
    setNewSiteMessage('');
  
    const token = localStorage.getItem('userToken');
  
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/bossNewSite`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(newSiteData),
      });
  
      if (!response.ok) {
        const errorData = await response.json(); 
        setNewSiteMessage(errorData.message || 'Error creating new site. Please try again.');
      } else {
        const responseData = await response.json();
        setNewSiteMessage(responseData.message); 
      }
    } catch (error) {
      console.error('Error creating new site:', error);
      setNewSiteMessage('Error creating new site. Please try again.');
    } finally {
      setNewSiteLoading(false);
    }
  };

  if (isLoading) {
    return <Loading />;
  }
  return (
    <Container>
      <Row>
        <Col>
          <Card className="mb-4">
            <Card.Header style={{ backgroundColor: 'black', textAlign: 'center', fontWeight: 'bold' }}>
              <Button variant="link" onClick={() => setIsOpen2(!isOpen2)} aria-controls="collapse-card-2" aria-expanded={isOpen2} style={{ color: 'white' }}>
                ADD NEW DATA PLAN
              </Button>
            </Card.Header>

            <Collapse in={isOpen2}>
              <Card.Body>
                <Form onSubmit={(e) => handleSubmit(e, formData, setFormData)}>
                  <Row>
                    <Col>
                      <Form.Group controlId="network">
                        <Form.Label>Network</Form.Label>
                        <Form.Control as="select" name="network" value={formData.network} onChange={handleChange} required>
                          <option value="">Select Network</option>
                          <option value="MTN">MTN</option>
                          <option value="AIRTEL">AIRTEL</option>
                          <option value="GLO">GLO</option>
                          <option value="9MOBILE">9MOBILE</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="planType">
                        <Form.Label>Type</Form.Label>
                        <Form.Control as="select" name="planType" value={formData.planType} onChange={handleChange} required>
                          <option value="">Select Plan Type</option>
                          <option value="SME">SME</option>
                          <option value="SME2">SME2</option>
                          <option value="CGD">CGD</option>
                          <option value="GIFTING">GIFTING</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="dataSize">
                        <Form.Label>Data Size</Form.Label>
                        <Form.Control type="number" name="dataSize" value={formData.dataSize} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="sizeType">
                        <Form.Label>Size Type</Form.Label>
                        <Form.Control as="select" name="sizeType" value={formData.sizeType} onChange={handleChange} required>
                          <option value="">Select Size Type</option>
                          <option value="MB">MB</option>
                          <option value="GB">GB</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="validity">
                        <Form.Label>Validity</Form.Label>
                        <Form.Control type="text" name="validity" value={formData.validity} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="companyPrice">
                        <Form.Label>Company Price</Form.Label>
                        <Form.Control type="text" name="companyPrice" value="0" onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="vtu9jaId">
                        <Form.Label>VTU9ja ID</Form.Label>
                        <Form.Control type="text" name="vtu9jaId" value={formData.vtu9jaId} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="salabebId">
                        <Form.Label>Salabeb ID</Form.Label>
                        <Form.Control type="text" name="salabebId" value={formData.salabebId} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="datastationId">
                        <Form.Label>Datastation ID</Form.Label>
                        <Form.Control type="text" name="datastationId" value={formData.datastationId} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="dancitysubId">
                        <Form.Label>Dancity ID</Form.Label>
                        <Form.Control type="text" name="dancitysuId" value={formData.dancitysuId} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="clubkonnect">
                        <Form.Label>ClubKonnect ID</Form.Label>
                        <Form.Control type="text" name="clubkonnectId" value={formData.clubkonnectId} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="bulksmsng">
                        <Form.Label>BulkSMS Nigeria ID</Form.Label>
                        <Form.Control type="text" name="bulksmsNg" value={formData.bulksmsNg} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group controlId="ibrolinksId">
                        <Form.Label>Ibrolinks ID</Form.Label>
                        <Form.Control type="text" name="ibrolinksId" value={formData.ibrolinksId} onChange={handleChange} required />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button variant="primary" type="submit" className="mt-2 w-100">
                    Add Plan
                  </Button>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>

          {/* Telegram Bot User Activation Collapse Card */}
          <Card className="mb-4">
            <Card.Header style={{ backgroundColor: 'black', textAlign: 'center', fontWeight: 'bold' }}>
              <Button variant="link" onClick={() => setIsOpenTg(!isOpenTg)} aria-controls="collapse-card-2" aria-expanded={isOpen2} style={{ color: 'white' }}>
                Telegram Bot User Activation
              </Button>
            </Card.Header>

            <Collapse in={isOpenTg}>
              <Card.Body>
                <Form onSubmit={handleActivateFormSubmit}>
                  <Row>
                    <Col>
                      <Form.Group controlId="activationDomain">
                        <Form.Label>Domain:</Form.Label>
                        <Form.Control
                          type="text"
                          name="activationDomain"
                          value={activationDomain}
                          placeholder='cart9.com.ng'
                          onChange={(e) => setActivationDomain(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  {activationMessage && <p style={{ color: activationMessage.includes('successfully') ? 'green' : 'red' }}>{activationMessage}</p>}

                  <Button type="submit" variant="primary" disabled={activationLoading}>
                    {activationLoading ? 'Loading...' : 'Activate'}
                  </Button>
                </Form>
              </Card.Body>
            </Collapse>
          </Card>



















          {/* NEW ACCOUNT Modal Card */}
          <Card className="mb-4">
            <Card.Header style={{ backgroundColor: 'black', textAlign: 'center', fontWeight: 'bold' }}>
              <Button variant="link" onClick={() => setIsOpenNewAcn(!isOpenNewAcn)} aria-controls="collapse-card-2" aria-expanded={isOpenNewAcn} style={{ color: 'white' }}>
                Create SITE
              </Button>
            </Card.Header>

            <Collapse in={isOpenNewAcn}>
              <Card.Body>
                <Form onSubmit={handleNewSiteFormSubmit}>
                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="domain">
                        <Form.Label>Domain:</Form.Label>
                        <Form.Control
                          type="text"
                          name="domain"
                          value={newSiteData.domain}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="siteName">
                        <Form.Label>Site Name:</Form.Label>
                        <Form.Control
                          type="text"
                          name="siteName"
                          value={newSiteData.siteName}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="siteEmail">
                        <Form.Label>Site Email:</Form.Label>
                        <Form.Control
                          type="email"
                          name="siteEmail"
                          value={newSiteData.siteEmail}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="motherSite">
                        <Form.Label>Mother Site:</Form.Label>
                        <Form.Control
                          type="text"
                          name="motherSite"
                          value={newSiteData.motherSite}
                          onChange={handleNewSiteChange}
                          
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="apiKey">
                        <Form.Label>API Key:</Form.Label>
                        <Form.Control
                          type="text"
                          name="apiKey"
                          value={newSiteData.apiKey}
                          onChange={handleNewSiteChange}
                          
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="phoneNumber">
                        <Form.Label>Phone Number:</Form.Label>
                        <Form.Control
                          type="number"
                          name="phoneNumber"
                          value={newSiteData.phoneNumber}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="customEmail">
                        <Form.Label>Custom Email:</Form.Label>
                        <Form.Control
                          type="email"
                          name="customEmail"
                          value={newSiteData.customEmail}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="customEmailPass">
                        <Form.Label>Custom Email Password:</Form.Label>
                        <Form.Control
                          type="password"
                          name="customEmailPass"
                          value={newSiteData.customEmailPass}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="customMailHot">
                        <Form.Label>Custom Mail Host:</Form.Label>
                        <Form.Control
                          type="text"
                          name="customMailHot"
                          value={newSiteData.customMailHot}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="customMailPort">
                        <Form.Label>Custom Mail Port:</Form.Label>
                        <Form.Control
                          type="number"
                          name="customMailPort"
                          value={newSiteData.customMailPort}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="textColor">
                        <Form.Label>Text Color:</Form.Label>
                        <Form.Control
                          type="color"
                          name="textColor"
                          value={newSiteData.textColor}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="backgroundColor">
                        <Form.Label>Background Color:</Form.Label>
                        <Form.Control
                          type="color"
                          name="backgroundColor"
                          value={newSiteData.backgroundColor}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="dashboardBG">
                        <Form.Label>Dashboard Background Color:</Form.Label>
                        <Form.Control
                          type="color"
                          name="dashboardBG"
                          value={newSiteData.dashboardBG}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                    <Col md={6}>
                      <Form.Group controlId="dashboardColor">
                        <Form.Label>Dashboard Text Color:</Form.Label>
                        <Form.Control
                          type="color"
                          name="dashboardColor"
                          value={newSiteData.dashboardColor}
                          onChange={handleNewSiteChange}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={6}>
                      <Form.Group controlId="isAlpha">
                        <Form.Label>Is Alpha:</Form.Label>
                        <Form.Control
                          as="select"
                          name="isAlpha"
                          value={newSiteData.isAlpha}
                          onChange={handleNewSiteChange}
                          required
                        >
                          <option value="">Select</option>
                          <option value="1">YES</option>
                          <option value="0">NO</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
<br />
                  {newSiteMessage && <p style={{ color: newSiteMessage.includes('successfully') ? 'green' : 'red' }}>{newSiteMessage}</p>}

                  <Button type="submit" variant="primary" disabled={newSiteLoading}>
                    {newSiteLoading ? 'Loading...' : 'Create New Site'}
                  </Button>
                </Form>

          </Card.Body>
        </Collapse>
      </Card>
 








     </Col>
      </Row>
    </Container>
  );
};

const UniversalDataPlan = () => {
  // Implement your Universal Data Plan display logic here
  return (
    <Card>
      <Card.Body>
        {/* Example code for displaying Universal Data Plans */}
        <p>Example Universal Data Plan display</p>
        {/* Add your ACTIVATE & DEACTIVATE button logic here */}
      </Card.Body>
    </Card>
  );
};

export default UniversalData;