import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col, Card, Table, Form } from 'react-bootstrap';
import Loading from '../components/Loading'; 

const DataPage = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [transactionsPerPage] = useState(5); // Number of transactions per page

  useEffect(() => {
    const token = localStorage.getItem('userToken');
    const domain = window.location.hostname;

    if (!token) {
      navigate('/');
    } else {
      fetchUserData(token, domain);
    }
  }, [navigate]);

  const fetchUserData = async (token, domain) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/app/userHistory`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Add Authorization header
        },
        body: JSON.stringify({ token, domain }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch user data');
      }

      const responseData = await response.json();

      if (responseData.status === 'failed') {
        localStorage.removeItem('userToken');
        navigate('/');
      } else {
        setUser(responseData.user);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      localStorage.removeItem('userToken');
      navigate('/'); // Redirect to login on error
    } finally {
      setIsLoading(false);
    }
  };

  // Pagination logic
  const indexOfLastTransaction = currentPage * transactionsPerPage;
  const indexOfFirstTransaction = indexOfLastTransaction - transactionsPerPage;
  const currentTransactions = user?.history?.slice(indexOfFirstTransaction, indexOfLastTransaction);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  if (isLoading) {
    return <Loading />; // Render the Loading component
  }

  return (
    <div style={{ backgroundColor: '#F9FAFB', minHeight: '100vh' }}>
      <Navbar bg="light" expand="lg">
        <Container>
          <Navbar.Brand onClick={() => navigate('/dashboard')} style={{ cursor: 'pointer' }}>
            Back
          </Navbar.Brand>
          <Nav className="ml-auto">
            <Nav.Link>History</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
      <Container className="mt-4">
        <Row className="justify-content-center">
          <Col md={12}>
            <Card className="balance-card mb-4">
              <Card.Body>
                <h4 className="mb-4">Transactions History</h4>
                {user && user.history && user.history.length > 0 ? (
                  <>
                    <Form.Group controlId="search">
                      <Form.Control
                        type="text"
                        placeholder="Search transactions..."
                        onChange={(e) => setSearchTerm(e.target.value)}
                      />
                    </Form.Group>
                    <Table striped bordered hover responsive>
                      <thead>
                        <tr>
                          <th>Date</th>
                          <th>Status/Token</th>
                          <th>Service /To</th>
                          <th>Amount</th>
                          <th>Balance</th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentTransactions
                          .filter((transaction) =>
                            `${transaction.service} ${transaction.phoneNumber}`
                              .toLowerCase()
                              .includes(searchTerm.toLowerCase())
                          )
                          .map((transaction) => (
                            <tr key={transaction.orderId}>
<td dangerouslySetInnerHTML={{ __html: transaction.date.replace(' at ', '<br />') }} />

                              <td>🔖 {transaction.orderStatus}<br /><small>{transaction.orderId}</small></td>
                              <td>{transaction.service}<br />{transaction.phoneNumber}</td>
                              <td>₦{transaction.amount}</td>
                              <td>OB: ₦{transaction.oldBal}<br />
                              NB: ₦{transaction.newBal}</td>
                              <td></td>
                     
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                    {/* Pagination */}
                    <ul className="pagination justify-content-center">
                      {Array.from({ length: Math.ceil(user.history.length / transactionsPerPage) }).map(
                        (item, index) => (
                          <li key={index} className="page-item">
                            <button onClick={() => paginate(index + 1)} className="page-link">
                              {index + 1}
                            </button>
                          </li>
                        )
                      )}
                    </ul>
                  </>
                ) : (
                  <p>No transaction history available.</p>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default DataPage;
